<template>
  <b-tab
    class="speces-model"
    :title="$t('specifictaionComponent/componentHead')"
  >
    <h3>
      {{ $t("specifictaionComponent/componentHead") }}
    </h3>
    <h2>
      {{
        isNotCompleted
          ? $t("g.specificationModel/error/allFormMustBeFilled")
          : null
      }}
    </h2>
    <div v-if="!noSpecKeys">
      <b-table :fields="fields" class="tabel" :items="specs" v-if="!isLoading">
        <template #cell(key)="data">
          <span> {{ data.item.name }}</span>
        </template>
        <template #cell(value)="data">
          <div
            :ref="data.item.key"
            contenteditable="true"
            @input="valueChanged(data.item.key)"
          >
            {{ form_data[data.item.key] }}
          </div>
        </template>
      </b-table>
      <b-row class="d-flex justify-content-end">
        <div
          class="col col-6 d-flex justify-content-end mt-2"
          v-if="
            globalPermission['spec-values'] &&
            globalPermission['spec-values'].store
          "
        >
          <b-button variant="primary" class="ml-1" @click="handelSubmit()">{{
            $t("specificationModel/form/save")
          }}</b-button>
        </div>
      </b-row>
    </div>
    <div class="no-spec-keys" v-else>
      {{ $t("g.product_specifiction/noSpecKeysAlrert") }}
    </div>
    <OverlayComponent :isLoading="isLoading" />
  </b-tab>
</template>
<script>
import { BModal, BTable, BRow, BCol, BButton, BTab } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
export default {
  components: {
    BModal,
    BTable,
    BRow,
    BCol,
    BButton,
    BTab,
    OverlayComponent,
  },
  data() {
    return {
      fields: [
        { label: this.$t("specifictionModel/key"), key: "key" },
        { label: this.$t("specifictionModel/value"), key: "value" },
      ],
      specs: [],
      form_data: {},
      isNotCompleted: false,
      noSpecKeys: false,
      isLoading: true,
      category_id: null,
      isEdit: false,
      archive: null,
    };
  },
  created() {
    this.getCatelogData();
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    catelogSlug() {
      return this.$route.params.catalogSlug;
    },
  },
  methods: {
    async getSpecsKeys() {
      try {
        const response = this.category_id
          ? await this.$http.get(
              `admin/spec-keys?category_id=${this.category_id}`
            )
          : null;
        if (response.status === 200 || response.status === 201) {
          this.specs = response.data.data;
          this.specs.map((ele) => {
            this.form_data[ele.key] = null;
          });
          this.getSpecsValues();
        } else {
          throw new Error(" ");
        }
      } catch (err) {
        if (err.response.status == 400) {
          this.noSpecKeys = true;
        } else {
          this.$helpers.handleError(err);
        }
        this.isLoading = false;
      }
    },
    async getSpecsValues() {
      this.isLoading = true;
      try {
        if (this.slug) {
          const response = await this.$http.get(
            `admin/products/${this.slug}/spec-values`
          );

          if (response.status === 200 || response.status === 201) {
            this.archive = {};
            if (response.data.data.length > 0) {
              this.isEdit = true;
              response.data.data.map(({ id, spec_key, value }) => {
                this.archive[spec_key] = value;
                this.specs.map((ele) => {
                  if (ele.key == spec_key) {
                    ele["id"] = id;
                    Object.assign(ele, { value });
                    this.form_data[spec_key] = value;
                  }
                });
              });
            } else {
              this.isEdit = false;
            }
          }
          this.isLoading = true;
        }
        this.isLoading = false;
      } catch (err) {
        if (err.response.status == 400) {
          this.isEdit = false;
          this.isLoading = false;
        }
        this.$helpers.handleError(err);
      }
    },
    valueChanged(key, value) {
      if (
        this.globalPermission["spec-values"] &&
        !this.globalPermission["spec-values"].update
      ) {
        this.form_data[key] = value || this.$refs[key].innerText;
      }
    },
    handelSubmit() {
      if (this.isEdit) {
        this.onEdit();
      } else {
        this.onSubmit();
      }
    },
    async onSubmit() {
      try {
        const formData = new FormData();
        for (const item in this.form_data) {
          if (this.form_data[item] !== null)
            formData.append(`${item}`, this.form_data[item]);
        }

        const response = await this.$http.post(
          `admin/products/${this.slug}/spec-values`,
          formData
        );
        if (response.status === 200 || response.status === 201) {
          this.$helpers.makeToast(
            "success",
            response.data.message,
            response.data.message
          );
          setTimeout(() => {
            this.$router.push({ name: "products" });
          }, 500);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async onEdit() {
      try {
        const updatedKeys = [];
        for (const item in this.form_data) {
          if (
            this.form_data[item] != this.archive[item] &&
            this.form_data[item] !== null
          ) {
            updatedKeys.push(...this.specs.filter((ele) => ele.key == item));
          }
        }
        for (const item in updatedKeys) {
          const formData = new FormData();
          formData.append("value", this.form_data[updatedKeys[item].key]);
          formData.append("lang", this.$i18n.locale);
          this.$http
            .post(
              `admin/products/${this.slug}/spec-values/${updatedKeys[item].id}?_method=put`,
              formData
            )
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                setTimeout(() => {
                  this.$router.push({ name: "products" });
                }, 500);
              }
            });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async getCatelogData() {
      if (this.catelogSlug) {
        try {
          const { status, data } = await this.$http.get(
            `admin/catalogs/${this.catelogSlug}`
          );
          if (status === 200 || status === 201) {
            this.category_id = data.data.category.id;
            this.getSpecsKeys();
          }
        } catch (err) {
          this.$helpers.handleError(err);
        }
      }
    },
    checkValues() {
      for (const item in this.form_data) {
        if (this.form_data[item] != null) {
          formData.append(item, this.form_data[item]);
        } else {
          this.isNotCompleted = true;
          throw new Error("all Elements should be added ");
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
