<template>
  <div class="show-product">
    <catalog-data-card
      v-if="
        catalogData && globalPermission.catalog && globalPermission.catalog.show
      "
      :selectedCatalogProp="catalogData"
    />
    <b-card>
      <b-tabs v-model="activeIndex" pills>
        <dashboard-component
          v-if="globalPermission.products && globalPermission.products.show"
          :productData="productData"
          :completed="is_completed"
        />
        <main-data-tab-component
          v-if="globalPermission.products && globalPermission.products.show"
        />
        <!-- <extra-model /> -->
        <specification-model
          v-if="
            globalPermission['spec-keys'] &&
            globalPermission['spec-values'].show
          "
        />
        <tags-tab-component
          v-if="globalPermission['tags'] && globalPermission['tags'].show"
        />
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import ExtraModel from "@/components/ProductsComponents/ExtraModel/index.vue";
import SpecificationModel from "@/components/ProductsComponents/SpecificationModel/index.vue";
import { BTab, BTabs, BCardText, BCard } from "bootstrap-vue";
import CatalogDataCard from "@/components/ProductsComponents/CatalogDataCard/index.vue";
import TagsTabComponent from "@/components/ProductsComponents/TagsTabComponent/index.vue";
import DashboardComponent from "@/components/ProductsComponents/DashboardComponent/index.vue";
import MainDataTabComponent from "@/components/ProductsComponents/MainDataTabComponent/index.vue";

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BCardText,
    CatalogDataCard,
    TagsTabComponent,
    DashboardComponent,
    MainDataTabComponent,
    SpecificationModel,
    ExtraModel,
  },
  data() {
    return {
      catalogData: null,
      categoryId: null,
      productData: null,
      is_completed: null,
      activeIndex: 0,
    };
  },
  created() {
    if (this.$route.query.tab) {
      this.activeIndex = 2;
    }
    if (this.$route.params.catalogSlug) {
      this.getCatalogData(this.$route.params.catalogSlug);
    }
    if (this.$route.params.slug) {
      this.getProductData(this.$route.params.slug);
    }
  },
  methods: {
    async getCatalogData(slug) {
      if (this.globalPermission.catalog && this.globalPermission.catalog.show) {
        try {
          this.overlayLoading = true;
          const response = await this.$http.get(`admin/catalogs/${slug}`);
          if (response.status === 200 || response.status === 201) {
            this.catalogData = response.data.data;
          }
        } catch (error) {
          this.$helpers.handleError(error);
        } finally {
          this.overlayLoading = false;
        }
      }
    },
    async getProductData(slug) {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`admin/products/${slug}`);
        if (response.status === 200 || response.status === 201) {
          this.productData = response.data.data;
          this.is_completed = response.data.data.is_completed;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
