<template>
  <b-tab class="extra-modal" :title="$t('g.extraComponent/Head')">
    <overlay-component :isLoading="busy" />
    <h3>{{ $t("g.extraComponent/Head") }}</h3>
    <validation-observer ref="addExtraForm">
      <b-row class="p-0 form">
        <b-col cols="12">
          <div class="extras">
            <div class="extra" v-for="(item, index) in extras" :key="item.id">
              {{ index + 1 }}.{{ item.name }}
            </div>
          </div>
        </b-col>
        <div class="d-flex">
          <b-col cols="6">
            <b-form-group
              :label="$t('g.extraModal/form/labels/name')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="form_data.name"
                  class="input-style py-2"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('g.extraModal/form/labels/price')"
              label-for="price"
            >
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-input
                  type="number"
                  v-model="form_data.price"
                  class="input-style py-2"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
      <b-row class="mb-1 px-1">
        <span class="label" v-if="!form_data.img">
          {{ $t("g.extraModal/form/importImg/text") }}
        </span>
        <b-form-file ref="formFileInput" hidden plain v-model="form_data.img" />
        <div
          class="fileInput-section col col-12"
          @click="openFileInput"
          v-if="!form_data.img"
        >
          <img :src="imortImgIco" />
          <span>{{ $t("g.extraModal/form/importImg/text") }}</span>
        </div>
        <div class="fileInput-section-preview col col-12" v-if="form_data.img">
          <span>
            <img :src="imgIco" alt="img-icon" class="img-ico" />
            <span>{{ form_data.img.name.split(".")[0] }}</span>
          </span>
          <button
            style="
               {
                all: unset;
              }
            "
            @click="form_data.img = null"
          >
            <img :src="penIco" alt="pen-icon" class="pen-ico" />
          </button>
        </div>
      </b-row>
      <b-row class="actions w-50">
        <b-button
          class="p-1 btn"
          variant="primary"
          :disabled="busy"
          @click="submitExtra"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="#fff" />
          </svg>
          {{ $t("g.extraModal/form/AddExtrabutton/text") }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-tab>
</template>
<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BCard,
  BTab,
} from "bootstrap-vue";
export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    OverlayComponent,
    BCard,
    BTab,
  },
  data() {
    return {
      required,
      form_data: {
        name: null,
        price: null,
        img: null,
      },
      imgIco: require("@/assets/images/icons/imgIco.png"),
      imortImgIco: require("@/assets/images/icons/importImgIco.svg"),
      penIco: require("@/assets/images/icons/pencil.svg"),
      extras: null,
      busy: false,
    };
  },
  created() {
    this.getExtrasForProduct();
  },
  methods: {
    openFileInput() {
      this.$refs.formFileInput.$el.click();
    },
    async submitExtra() {
      try {
        this.busy = true;
        const validationResualt = await this.$refs.addExtraForm.validate();
        if (validationResualt) {
          if (this.slug) {
            const formData = new FormData();
            for (let item in this.form_data) {
              if (item != "img") {
                formData.append(item, this.form_data[item]);
              } else if (item == "img" && this.form_data["img"]) {
                formData.append("image", this.form_data[item]);
              }
            }
            const response = await this.$http.post(
              `admin/products/${this.slug}/extras`,
              formData
            );
            if (response.status === 200 || response.status === 201) {
              this.$helpers.makeToast(
                "success",
                response.data.message,
                response.data.message
              );
              this.resetFormData();
              this.getExtrasForProduct();
              this.busy = false;
            }
          }
        } else {
          this.busy = false;
        }
      } catch(err) {
        this.busy = false;
        this.$helpers.handleError(err);
      }
    },
    async getExtrasForProduct() {
      this.busy = true;
      try {
        if (this.slug) {
          const response = await this.$http.get(
            `admin/products/${this.slug}/extras`
          );
          if (response.status === 200 || response.status === 201) {
            this.extras = response.data.data;
            this.busy = false;
          }
        } else {
          throw new Error("");
        }
      } catch(err) {
        this.$helpers.handleError(err);
      }
    },
    resetFormData() {
      for (const item in this.form_data) {
        this.form_data[item] = null;
      }
      this.$refs.addExtraForm.reset();
    },
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
};
</script>
<style lang="scss">
@import "./_index.scss";
</style>
