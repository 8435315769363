<template>
  <b-tab :title="$t('show_product/tab_title/main_data')">
    <validation-observer ref="EditProductForm">
      <b-form class="d-flex flex-column">
        <b-card class="product-card-container">
          <b-row>
            <b-col v-if="vendorsData" class="mb-2" cols="12">
              <!-- Start Dropdown -->
              <b-form-group
                :label="$t('add_product/vendor')"
                label-for="Vendor"
              >
                <validation-provider #default="{ errors }" name="Vendor">
                  <b-form-select
                    :disabled="!canEdit"
                    v-model="form_data.vendor_id"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-select-option
                      :disabled="!canEdit"
                      v-for="vendor in vendorsData"
                      :key="vendor.id"
                      :value="vendor.id"
                    >
                      {{ vendor.store_name }}
                    </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Dropdown -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/suffix_name')"
                label-for="Suffix_Name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Suffix_Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.suffix_name"
                    :disabled="!canEdit"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sku')" label-for="SKU">
                <validation-provider #default="{ errors }" name="SKU">
                  <b-form-input
                    v-model="form_data.sku"
                    :disabled="!canEdit"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/price')" label-for="Price">
                <validation-provider
                  #default="{ errors }"
                  name="Price"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.price"
                    :disabled="!canEdit"
                    type="number"
                    step=".1"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group :label="$t('add_product/sale')" label-for="Sale">
                <validation-provider #default="{ errors }" name="Sale">
                  <b-form-input
                    v-model="form_data.sale"
                    :disabled="!canEdit"
                    type="number"
                    step=".1"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                :label="$t('add_product/quantity')"
                label-for="Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Quantity"
                  rules="required"
                >
                  <b-form-input
                    :disabled="!canEdit"
                    v-model="form_data.qty"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" sm="6">
              <!-- Start Input -->
              <b-form-group
                class="w-100"
                :label="$t('add_product/max_quantity')"
                label-for="Maximum Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Maximum Quantity"
                  class="w-100"
                >
                  <b-form-input
                    :disabled="!canEdit"
                    v-model="form_data.max_qty"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <!-- min stock   -->
            <b-col class="mb-2" cols="12" sm="6">
              <b-form-group
                :label="$t('g.settings/generalSettings/min_stock')"
                label-for="Min stock"
              >
                <validation-provider #default="{ errors }" name="Min stock">
                  <b-form-input
                    :disabled="!canEdit"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.min_stock"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **min stock   -->
            <b-col class="mb-2" cols="12">
              <!-- Start Input -->
              <div class="product-images-box">
                <product-main-image-component
                  @set-main-image="handelMainImage"
                  :image="productMainImage"
                />
              </div>
              <product-images-component
                :files="form_data.productImages"
                :product-slug="productSlug"
                :images="productImages"
                @set-product-images="handelImages"
                @handel-is-main-change="handelIsMain"
                @delete-image-softly="deleteImage"
              />
              <!-- v-if="
                  !form_data.productImages ||
                  form_data.productImages.length == 0
                " -->
              <RecommendationUploadImage
                :dimensions="{ width: 1024, height: 1024 }"
              />
            </b-col>

            <b-col cols="12">
              <div class="switches-box">
                <b-form-checkbox
                  :disabled="!canEdit"
                  v-model="form_data.is_visible"
                  name="check-button"
                  switch
                >
                  {{ $t("add_product/is_visiable") }}
                </b-form-checkbox>
                <b-form-checkbox
                  :disabled="!canEdit"
                  v-model="form_data.is_free_shipping"
                  name="check-button"
                  switch
                >
                  {{ $t("add_product/is_free_shipping") }}
                </b-form-checkbox>
                <b-form-checkbox
                  :disabled="!canEdit"
                  v-model="form_data.is_has_vat"
                  name="check-button"
                  switch
                >
                  {{ $t("g.value_added_tax") }}
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-button
          v-if="globalPermission.products && globalPermission.products.update"
          variant="primary"
          class="mb-5 align-self-end"
          @click="handleSubmitProduct"
        >
          {{ $t("add_product/save_changes") }}
        </b-button>
      </b-form>
    </validation-observer>
    <overlay-loading :is-loading="overlayLoading" />
    <Image-cropper-component
      :images-array="imagesToCrop"
      :image-type="imageToCropIsMain"
      @return-main-image="handelCroppedMainImage"
      @return-not-main-image="handelCroppedNotMainImage"
      @handel-cancel-main-image="handelCancelMainImage"
      @handel-cancel-not-main-image="handelCancelNotMainImage"
    />
  </b-tab>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormSelectOption,
  BFormFile,
  BButton,
  BForm,
  BTab,
  BFormCheckbox,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OverlayLoading from "@/components/shared/OverlayComponent/index.vue";
import ProductMainImageComponent from "@/components/ProductsComponents/MainDataTabComponent/MainImageComponent/index.vue";
import ProductImagesComponent from "@/components/ProductsComponents/MainDataTabComponent/ProductImagesComponent/index.vue";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";
import ImageCropperComponent from "@/components/shared/ImageCropperComponent";
export default {
  components: {
    ProductImagesComponent,
    ProductMainImageComponent,
    BRow,
    BCol,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BButton,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormCheckbox,
    BForm,
    OverlayLoading,
    RecommendationUploadImage,
    ImageCropperComponent,
  },
  props: ["handleOverlayLoading"],
  data() {
    return {
      required,
      overlayLoading: false,
      productSlug: null,
      vendorsData: null,
      productImages: [],
      imageLoading: false,
      productMainImage: {},
      form_data: {
        vendor_id: null,
        suffix_name: null,
        sku: null,
        max_qty: null,
        min_qty: 1,
        qty: null,
        sale: null,
        price: null,
        mainImage: null,
        min_stock: null,
        productImages: [],
        is_visible: true,
        is_free_shipping: false,
        is_has_vat: false,
      },
      imagesToCrop: [],
      imageToCropIsMain: false,
      maxQtyActive: false,
      addExatraIcon: require("@/assets/images/icons/Lead icon.svg"),
      addImageIcon: require("@/assets/images/icons/add-image-icon.svg"),
      addProductImagesIcon: require("@/assets/images/icons/add-product-images.svg"),
    };
  },
  created() {
    this.getVendorsData();
    if (this.$route.params.slug) {
      this.productSlug = this.$route.params.slug;
      this.getProductData(this.$route.params.slug);
    }
  },
  computed: {
    canEdit() {
      return (
        (this.globalPermission.products &&
          this.globalPermission.products.update) === true
      );
    },
  },
  methods: {
    async getVendorsData() {
      try {
        const response = await this.$http.get("admin/vendors");
        this.vendorsData = response.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async getProductData(slug) {
      try {
        this.overlayLoading = true;
        const response = await this.$http.get(`admin/products/${slug}`);
        if (response.status > 299) {
          throw Error();
        }
        this.form_data = {
          vendor_id: response.data.data.vendor?.id,
          suffix_name: response.data.data.suffix_name,
          sku: response.data.data.sku,
          max_qty: response.data.data.max_qty,
          min_qty: response.data.data.min_qty,
          qty: response.data.data.qty,
          sale: +response.data.data.sale ? response.data.data.sale : null,
          price: response.data.data.price,
          min_stock: response.data.data.min_stock,
          is_visible: response.data.data.is_visible,
          is_free_shipping: response.data.data.is_free_shipping,
          is_has_vat: Boolean(response.data.data.is_has_vat),
          productImages: [],
          mainImage: null,
        };
        this.maxQtyActive = this.form_data.max_qty !== null;
        if (response.data.data.images.length > 0) {
          response.data.data.images.map(({ id, path, is_main }) => {
            is_main
              ? (this.productMainImage = { path, id })
              : this.productImages.push({ id, path, is_main });
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    handelResetImages() {
      this.imagesToCrop.length = 0;
    },
    deleteImage(deletedImage) {
      this.form_data.productImages = this.form_data.productImages.filter(
        (image) => image.path !== deletedImage.path
      );
      this.productImages = this.productImages.filter(
        (image) => image.path !== deletedImage.path
      );
    },
    handelMainImage(file) {
      this.imagesToCrop.length = 0;
      this.imageToCropIsMain = true;
      this.form_data.mainImage = file;
      this.imagesToCrop.push(file);
      this.$bvModal.show("image-cropper-component");
    },
    handelCroppedMainImage(file) {
      if (this.productMainImage.path) {
        this.productImages.push({
          path: this.productMainImage.path,
          id: this.productMainImage.id,
        });
      }
      this.form_data.mainImage = file;
      this.productMainImage = file;
      this.imageToCropIsMain = false;
    },
    handelCroppedNotMainImage(files) {
      files.forEach((file) => {
        this.form_data.productImages.push(file);
        this.productImages.push(file);
      });
    },
    handelCancelMainImage(file) {
      this.form_data.mainImage = file;
      this.productMainImage = file;
      this.imageToCropIsMain = false;
    },
    handelCancelNotMainImage(files) {
      this.imagesToCrop.length = 0;
      files.forEach((file) => {
        this.form_data.productImages.push(file);
        this.productImages.push(file);
      });
    },
    async handleSubmitProduct() {
      this.$refs.EditProductForm.validate().then(async (success) => {
        if (success) {
          try {
            this.overlayLoading = true;
            const data = this.collectData();
            const res = await this.$http.post(
              `admin/products/${this.productSlug}?_method=put`,
              data
            );
            if (res.status > 299) {
              throw Error();
            }
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
            this.$router.push({ name: "products" });
          } catch (error) {
            this.$helpers.handleError(error);
          } finally {
            this.overlayLoading = false;
          }
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    handelImages(files) {
      this.imagesToCrop.length = 0;
      this.imageToCropIsMain = false;
      files.forEach((file) => {
        const fileObJ = { file, path: URL.createObjectURL(file) };
        this.imagesToCrop.push(fileObJ);
      });
      this.$bvModal.show("image-cropper-component");
    },
    handelIsMain({ path, id, file }) {
      if (id) {
        this.productImages = this.productImages.filter((img) => img.id !== id);
        this.productImages.push({
          path: this.productMainImage.path,
          id: this.productMainImage.id,
        });
        this.productMainImage = { path, id };
      } else {
        if (this.productMainImage.path && this.productMainImage.id) {
          this.productImages.push({
            path: this.productMainImage.path,
            id: this.productMainImage.id,
          });
        }
        this.productImages = this.productImages.filter(
          (img) => img.path !== path
        );
        this.form_data.productImages = this.form_data.productImages.filter(
          (file) => file.path !== path
        );

        this.productMainImage = { path, file };
        this.form_data.mainImage = { path, file };
      }
    },
    collectData() {
      const formData = new FormData();
      let currentIndex = 0;
      for (const item in this.form_data) {
        if (item === "mainImage") {
          this.form_data[item] &&
            formData.append(
              `images[${currentIndex}][file]`,
              this.form_data[item].file
            );
          this.form_data[item] &&
            formData.append(`images[${currentIndex}][is_main]`, 1);
          currentIndex++;
        } else if (item === "productImages") {
          Array.from(this.form_data[item]).forEach((file, index) => {
            formData.append(`images[${currentIndex}][file]`, file.file);
            formData.append(`images[${currentIndex}][is_main]`, 0);
            currentIndex++;
          });
        } else if (
          item === "is_visible" ||
          item === "is_free_shipping" ||
          item === "is_has_vat"
        ) {
          formData.append(item, this.form_data[item] ? 1 : 0);
        } else if (item === "sku") {
          this.form_data.sku && formData.append(item, this.form_data[item]);
        } else if (item === "sale") {
          if (this.form_data[item] && this.form_data[item] !== "0.00")
            formData.append(item, this.form_data[item]);
        } else if (item === "max_qty") {
          this.form_data.max_qty && formData.append(item, this.form_data[item]);
        } else {
          formData.append(item, this.form_data[item]);
        }
      }
      formData.append("lang", localStorage.getItem("lang"));
      return formData;
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
