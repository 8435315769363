<template>
  <b-form-group :label="$t('add_product/main_image')" label-for="Main Image">
    <validation-provider #default="{ errors }" name="Main Image">
      <div class="main-image">
        <div class="main-img">
          <div class="image-controlles">
            <b-button variant="primary" size="sm">
              <b-form-file
                name="mainImageInput"
                ref="mainImage"
                accept="image/*"
                class="main-image-input"
                v-model="file"
                :state="errors.length > 0 ? false : null"
                @change="handleProductMainImage"
              />
              {{ $t("replace_image") }}
            </b-button>
          </div>
          <img
            class="main-img"
            :src="image.path || addImageIcon"
            style="height: 100%; object-fit: cover"
            alt="product main image"
          />
        </div>
      </div>
      <RecommendationUploadImage
        :dimensions="{ width: 1024, height: 1024 }"
      />
      <small class="text-danger">
        {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
      </small>
      <overlay-loading :isLoading="imageLoading" />
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BButton, BFormFile, BFormGroup } from "bootstrap-vue";
import OverlayLoading from "@/components/shared/OverlayComponent/index.vue";
import { ValidationProvider } from "vee-validate";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  name: "ProductMainImageComponent",
  props: {
    image: {
      required: false,
    },
  },
  components: {
    BButton,
    ValidationProvider,
    OverlayLoading,
    BFormGroup,
    BFormFile,
    RecommendationUploadImage
  },

  data() {
    return {
      file: null,
      productMainImage: null,
      addImageIcon: require("@/assets/images/icons/add-image-icon.svg"),
      imageLoading: false,
    };
  },
  methods: {
    async handleProductMainImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.productMainImage = URL.createObjectURL(file);
        this.$emit("set-main-image", { file, path: this.productMainImage });
      }
    },
  },
};
</script>
