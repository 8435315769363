<template>
  <b-form-group
    style="flex: 1; width: 100%"
    :label="$t('add_product/images_of_product')"
    label-for="Product Images"
  >
    <validation-provider #default="{ errors }" name="Product Images">
      <div class="product-images">
        <b-form-file
          v-model="files"
          multiple
          plain
          ref="productImagesComponent"
          hidden="hidden"
          accept="image/*"
          class="product-images-input"
          @change="handelInputChange"
          :state="errors.length > 0 ? false : null"
        />
        <img
          :src="addProductImagesIcon"
          alt="Add Product Images"
          @click.prevent="$refs.productImagesComponent.$el.click()"
        />
        <div
          v-for="(image, index) in productImages"
          :key="index"
          class="product-img"
        >
          <div class="image-controlles" v-if="!imageLoading">
            <b-button size="sm" variant="primary" @click="setIsMain(image)">
              {{ $t("set_as_main") }}
            </b-button>
            <b-button
              size="sm"
              variant="outline-danger"
              @click="deleteImages(image)"
            >
              {{ $t("delete_image") }}
            </b-button>
          </div>
          <img :src="image.path" alt="Product Image" />
        </div>
        <small class="text-danger">
          {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
        </small>
        <overlay-loading :isLoading="imageLoading" />
      </div>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BButton, BFormFile, BFormGroup } from "bootstrap-vue";
import OverlayLoading from "@/components/shared/OverlayComponent/index.vue";
import { ValidationProvider } from "vee-validate";

export default {
  name: "ProductImagesComponent",
  props: {
    images: {
      type: Array,
    },
    productSlug: {
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  components: {
    BButton,
    ValidationProvider,
    OverlayLoading,
    BFormGroup,
    BFormFile,
  },
  data() {
    return {
      imageLoading: false,
      productImages: [],
      addProductImagesIcon: require("@/assets/images/icons/add-product-images.svg"),
    };
  },
  computed: {
    // allImages() {
    //   return [...this.images, ...this.productImages];
    // },
  },
  mounted() {
    this.productImages = this.images;
  },
  methods: {
    handleProductImages(files) {
      files.forEach((fileEle) => {
        const { path, id, is_main, file } = fileEle;
        if (fileEle.file) {
          this.productImages.push({ path, file });
        } else {
          this.productImages.push({ path, id, is_main });
        }
      });
    },
    handelInputChange(event) {
      const files = event.target.files;
      this.$emit("set-product-images", files);
    },
    async deleteImages(imgObj) {
      try {
        this.imageLoading = true;
        if (!imgObj.file && imgObj.id) {
          const deleteRequest = await this.$http.delete(
            `admin/image/${imgObj.id}`
          );
          if ([200, 201].includes(deleteRequest.status)) {
            this.productImages = this.productImages.filter(
              (img) => img.path !== imgObj.path
            );
          }
        } else {
          this.productImages = this.productImages.filter(
            (img) => img.path !== imgObj.path
          );
        }
        this.$emit("delete-image-softly", imgObj);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.imageLoading = false;
      }
    },
    async setIsMain({ id, path, file }) {
      try {
        this.imageLoading = true;
        if (id) {
          const formData = new FormData();
          formData.append("image_id", id);
          formData.append("is_main", 1);
          await this.$http.post(
            `admin/products/${this.productSlug}?_method=put`,
            formData
          );
          this.$emit("handel-is-main-change", { path, id });
        } else {
          this.$emit("handel-is-main-change", { path, file });
        }
      } catch (err) {
        this.$helpers.handleError(err);
      } finally {
        this.imageLoading = false;
      }
    },
  },
  watch: {
    images(newValue) {
      this.productImages = newValue;
    },
  },
};
</script>
<style lang="scss">
.product-images {
  height: 178px;
  flex: 1;
  min-height: 100%;
  display: flex;
  gap: 20px;
  padding: 0px 15px;
  align-items: center;
  overflow-x: auto;
  border-radius: 4px;
  border: 2px dashed #e3e3e3;
  background: #fff;
  position: relative;
  .product-images-input {
    width: 187px;
    height: 170px;
    opacity: 0;
    z-index: 10;
    position: absolute;
    .custom-file-label {
      cursor: pointer;
    }
  }
  .product-img {
    border-radius: 5px;
    position: relative;
    .image-controlles {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.5s;
      position: absolute !important ;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      z-index: 1;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.671);
    }
    .image-controlles:hover {
      opacity: 1;
    }
    img {
      width: 180px;
      height: 170px;
      min-width: 180px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
</style>
