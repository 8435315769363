<template>
  <div class="rate-component" title="">
    <h3>{{ $t("g.rateComponent/head") }}</h3>
    <span class="d-flex" v-if="rate_data">
      <p>{{ rate_data.overall }}.0</p>
      <star-icon
        color="yellow"
        v-for="index in rate_data.overall"
        :key="index"
      />
    </span>
    <p class="mt-n2" v-if="rate_data">{{ componentHead }}</p>
    <p class="mt-n2" v-else>{{ $t("no_rating") }}</p>
    <div class="group">
      <div class="rate-row" v-for="(item, index) in rates" :key="item.star">
        <span> {{ index + 1 }}.0</span>
        <span class="presenting">
          <star-icon :color="colorRates[index + 1].star" />
          <progress-bar
            :value="item.percent"
            :color="colorRates[index + 1].bar"
            :total="100"
            :class="`my-2 ${colorRates[index + 1].bar} Progress-bar`"
          />
          <span> ({{ item.count }})</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BFormCheckbox,
  BFormCheckboxGroup,
  BProgress,
  BProgressBar,
  BTab,
} from "bootstrap-vue";
import starIcon from "@/components/productReviews/star";
import Loading from "@/components/shared/loading/loading.vue";
import progressBar from "@/components/productReviews/progressBar";
export default {
  name: "rateComponent",
  props: ["data", "filters", "productData"],
  components: {
    starIcon,
    progressBar,
    BFormCheckbox,
    BFormCheckboxGroup,
    Loading,
    BProgress,
    BTab,
    BProgressBar,
  },

  data() {
    return {
      filterSelect: [],
      colorRates: {
        5: { bar: "green", star: "yellow" },
        4: { bar: "blue", star: "blue" },
        3: { bar: "orange", star: "orange" },
        2: { bar: "red", star: "red" },
        1: { bar: "red", star: "red" },
      },
      rate_data: null,
      rating: null,
      isLoading: true,
      rates: null,
    };
  },
  created() {
    this.getReviews();
  },
  methods: {
    filtring() {
      this.$emit("handelFilter", this.filterSelect);
    },
    async getReviews() {
      try {
        const res = await this.$http.get(
          `admin/products/${this.$route.params.slug}/reviews`
        );
        if (res.status === 200 || res.status === 201) {
          this.rate_data = res.data.rates;
          this.rates = this.handleRates(this.rate_data);
          this.isLoading = false;
        }
      } catch (err) {
        this.$helpers.handleError(err);
        this.isLoading = true;
      }
    },
    getRatePercentValue(rateIndex) {
      let ratePercent = null;
      switch (rateIndex) {
        case "1":
          ratePercent = this.rate_data.rates.stars_one.percent;
          break;
        case "2":
          ratePercent = this.rate_data.rates.stars_two.percent;
          break;
        case "3":
          ratePercent = this.rate_data.rates.stars_three.percent;
          break;
        case "4":
          ratePercent = this.rate_data.rates.stars_four.percent;
          break;
        case "5":
          ratePercent = this.rate_data.rates.stars_five.percent;
          break;
        default:
          break;
      }
      return ratePercent;
    },

    handleRates(data) {
      const rates = [];
      for (let item in data) {
        const notWantedData = ["overall", "count"];
        if (!notWantedData.includes(item)) {
          rates.push(data[item]);
        }
      }
      return rates;
    },
  },
  computed: {
    componentHead() {
      return this.$t("g.productReview/rateComponent/head").replace(
        ":reviews",
        this.rate_data?.count
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./Rating.scss";
</style>
